.nts-locker-page {
    min-height: calc(100vh - 297px);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow-y: auto;
    padding-top: 20px;

    .load-more-target {
        height: 30px;
    }

    .title {
        font-family: "TradeGothicCondensed", TradeGothic, Arial, sans-serif;
        font-size: 36px;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    .action-row {
        display: grid;
        row-gap: 10px;

        .button-row {

            .btn {
                min-width: 100px;
                border-radius: 2px;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            .filters {

                .filter-select {
                    display: inline-block;
                    margin-right: 10px;
                }

                .trash-icon {
                    margin-bottom: 10px;
                }

                .filter-select {
                    width: 200px;

                    .Select-control {
                        height: 40px;
                        border-radius: 2px;

                        .Select-input input {
                            border: none;
                            padding: 0;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
            }


        }

        .trash-icon {
            cursor: pointer;
            height: 20px;
            color: #8D8D8D;

            &:hover {
                color: #FA5400;
            }
        }

        input, textarea {
            font-size: 14px;
            border-radius: 0;
            height: 40px;
            border: 1px solid #ccc;
            padding: 5px;

            &.error {
                border-color: red;
            }
        }

        .set-name-input {
            width: 270px;
        }

        .email-form-input {
            width: 350px;
        }

        .email-message-input {
            border: 1px solid #ccc;
            height: 90px;
        }
    }

    .locker-empty {
        font-size: 30px;
        padding-top: 20px;
    }

    .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, 240px);
        padding: 24px 0;

        .grid-item-wrapper {
            position: relative;
            box-sizing: border-box;
            height: 350px;

            .grid-item {
                background-color: #FFF;
                box-sizing: border-box;
                border: 1px solid transparent;
                cursor: pointer;
                width: 240px;
                padding: 15px;
                position: absolute;
                top: 0;
                left: 0;

                .grid-image-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    height: 200px;
                    margin-top: 15px;

                    &.not-orderable {
                        opacity: 0.5;
                        cursor: auto;
                    }

                    .grid-image {
                        height: 185px;
                        margin-top: 0;

                        &.prebuild-image {
                            max-width: unset;
                            height: 200px;
                            margin-top: 0;
                        }

                    }
                    &.custom-sap {
                        img {
                            height: 200px;
                            margin-top: 0;
                        }
                    }
                    &.prebuild-image {
                        overflow: hidden;
                    }
                }

                .grid-item-thumbs {
                    height: 55px;
                    overflow: hidden;
                    display: none;
                    text-align: center;
                    position: relative;
                    .grid-cell-color-option {
                        height: 45px;
                        min-width: 40px;
                        padding: 5px;
                        display: none;
                        cursor: pointer;
                        user-select: none;
                        margin-top: 5px;
                        &.showing {
                            display: inline-block;
                        }
                    }
                    .grid-cell-thumb-toggle {
                        height: 45px;
                        width: 45px;
                        display: inline-block;
                        padding: 5px;
                        color: $med-gray;
                        cursor: pointer;
                        z-index: 1;
                        &:hover {
                            color: $black;
                        }
                        &.left {
                            position: absolute;
                            top: 3px;
                            left: 0;
                            text-align: left;
                        }
                        &.right {
                            position: absolute;
                            right: 0;
                            top: 3px;
                            text-align: right;
                        }
                        I {
                            font-size: 28px;
                        }
                    }

                }

                .uniform-id-line {
                    align-items: baseline;
                    border-bottom: 1px solid #bbb;
                    height: 21px;
                    display: grid;
                    grid-template-columns: auto minmax(0, 100px);

                    .custom-personal {
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: -0.2px;
                        margin-left: -5px;
                    }

                    .uid {
                        color: #333;
                        font-size: 11px;
                        font-weight: 700;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .name-line {
                    color: #333;
                    font-size: 11px;
                    font-weight: 700;
                    padding: 3px 0;
                }

                .price-line {
                    color: #6D6D6D;
                    font-size: 11px;
                    font-weight: 700;
                }

                .trash-icon {
                    display: none;
                    position: absolute;
                    left: 20px;
                    top: 10px;
                    height: 20px;
                    color: #8D8D8D;

                    &:hover {
                        color: #FA5400;
                    }
                    &.active {
                        display: block;
                    }
                }

                .checkbox {
                    position: absolute;
                    top: 0;
                    right: 15px;
                }

                .add-to-cart-icon {
                    display: none;
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    height: 20px;
                    color: #8D8D8D;

                    &:hover {
                        color: #FA5400;
                    }
                    &.active {
                        display: block;
                    }
                }

                &:hover {
                    border: 1px solid #ccc;
                    z-index: 2;
                    .grid-item-thumbs {
                        display: block;
                    }
                }
            }
        }

    }

    /*** Mobile Stylings - Small Screen ***/
    @media ($screen-sm-max) {
        &.container {
            padding: 20px;

            .action-row {
                .action-grid {
                    grid-template-columns: 1fr;
                }
            }

            .grid-wrapper {
                grid-template-columns: repeat(auto-fill, 100%);

                .grid-item {
                    width: 100%;
                    max-width: 300px;

                    .grid-item-thumbs {
                        display: block;
                    }
                }
            }
        }
    }
}
